import Text from '@latitude/text';
import cn from 'classnames';
import { Accordion } from '@anchorage/common/dist/components';
import { AccordionHeader, AccordionPanel } from '@anchorage/common/dist/components/Accordion';
import { AddUserIcon, BrowserTransferIcon, DomainIcon, EmptyPersonIcon, PersonIdentificationIcon } from '@anchorage/common/dist/components/Icons';
import css from './styles.module.scss';
import affiliateLandingPageAnimation from './images/affiliate-landing-page.json';
import individualLandingPageAnimation from './images/individual-landing-page.json';
export enum FORM_TYPE {
  AFFILIATE = 'AFFILIATE',
  INDIVIDUAL = 'INDIVIDUAL',
}
export const LANDING_PAGE_IMAGE = {
  AFFILIATE: affiliateLandingPageAnimation,
  INDIVIDUAL: individualLandingPageAnimation
};
export enum LANDING_PAGE_DRAWERS {
  ACCOUNT_SETUP = 'ACCOUNT_SETUP',
  EXPECTED_ACTIVITY = 'EXPECTED_ACTIVITY',
  ORGANIZATION_DETAILS = 'ORGANIZATION_DETAILS',
}
export const LANDING_PAGE_CARDS_CONTENT = {
  AFFILIATE: [{
    icon: <DomainIcon />,
    drawerID: LANDING_PAGE_DRAWERS.ORGANIZATION_DETAILS,
    title: 'Provide organization details',
    description: 'For legal and regulatory compliance, provide your organization’s name, business activities, and principal place of business'
  }, {
    icon: <AddUserIcon />,
    drawerID: LANDING_PAGE_DRAWERS.ACCOUNT_SETUP,
    title: 'Setup account',
    description: 'Add at least 3 authorized users and select your preferred contact details for receiving important communications'
  }, {
    icon: <BrowserTransferIcon />,
    drawerID: LANDING_PAGE_DRAWERS.EXPECTED_ACTIVITY,
    title: 'Specify expected activity',
    description: 'Provide details about your anticipated activities to help us establish your transactional profile in compliance with regulatory requirements'
  }],
  INDIVIDUAL: [{
    icon: <EmptyPersonIcon />,
    drawerID: undefined,
    title: 'Provide personal details',
    description: 'To ensure legal documentation and regulatory compliance, provide personal details including your name, email address, date of birth, physical address, social security number, and a government issued ID.'
  }, {
    icon: <PersonIdentificationIcon />,
    drawerID: undefined,
    title: 'Verify your identity',
    description: "You'll need to upload a photo ID for identity verification. This helps us prevent fraud and ensures regulatory compliance."
  }]
};
export const LANDING_PAGE_DRAWERS_CONTENT = {
  [LANDING_PAGE_DRAWERS.ACCOUNT_SETUP]: {
    title: 'Adding authorized users',
    content: <>
        <Text>
          For initial account setup, we need to add authorized users. You can
          modify these settings in more detail once you access the Anchorage
          Digital app.
        </Text>
        <Accordion expanded className={css.accordionContainer}>
          <AccordionHeader>
            <Text variant="headingSmall">Authorized users</Text>
          </AccordionHeader>
          <AccordionPanel>
            <div className={cn([css.drawerText, css.drawerTextAccordion])}>
              <Text>
                To enable quorum-based approvals, you must add at least 3
                authorized users from your organization. These users will have
                full administrative control by default.
              </Text>
              <Text>
                An authorized user is an individual designated by the client to
                perform transactions and participate in quorum actions within
                the Anchorage Digital app.
              </Text>
              <Text>
                Authorized users may perform sensitive operations, such as:
              </Text>
              <ul className={css.list}>
                <li>
                  <Text>Creating vaults</Text>
                </li>
                <li>
                  <Text>Withdrawing assets</Text>
                </li>
                <li>
                  <Text>Interacting with dApps and smart contracts</Text>
                </li>
                <li>
                  <Text>Adding or removing users</Text>
                </li>
                <li>
                  <Text>Allowlisting addresses</Text>
                </li>
                <li>
                  <Text>Decrypting wallet keys</Text>
                </li>
              </ul>
            </div>
          </AccordionPanel>
        </Accordion>
        <Accordion expanded className={css.accordionContainer}>
          <AccordionHeader>
            <Text variant="headingSmall">Document delivery</Text>
          </AccordionHeader>
          <AccordionPanel>
            <div className={cn([css.drawerText, css.drawerTextAccordion])}>
              <Text>
                Provide one or more email addresses to receive important account
                notifications, including:
              </Text>
              <ul className={css.list}>
                <li>
                  <Text>Invoices</Text>
                </li>
                <li>
                  <Text>KYC inquiries</Text>
                </li>
                <li>
                  <Text>Platform service updates</Text>
                </li>
              </ul>
            </div>
          </AccordionPanel>
        </Accordion>
      </>
  },
  [LANDING_PAGE_DRAWERS.EXPECTED_ACTIVITY]: {
    title: 'What type of question will I need to answer?',
    content: <>
        <Text>
          To understand your transactional profile and to ensure regulatory
          compliance, you will need to provide details on:
        </Text>
        <ul className={css.list}>
          <li>
            <Text>Expected digital asset and fiat transactions</Text>
          </li>
          <li>
            <Text>Anticipated account usage</Text>
          </li>
          <li>
            <Text>Transaction ranges and volumes</Text>
          </li>
          <li>
            <Text>Asset custody amounts</Text>
          </li>
          <li>
            <Text>Sources of funds</Text>
          </li>
        </ul>

        <Text>This information helps us maintain regulatory standards.</Text>
      </>
  },
  [LANDING_PAGE_DRAWERS.ORGANIZATION_DETAILS]: {
    title: 'What information should I gather?',
    content: <div className={css.orgDetailsContainer}>
        <Accordion expanded className={css.accordionContainer}>
          <AccordionHeader>
            <Text variant="bodyBold">Basic business information</Text>
            <Text variant="captionRegular" className={css.subText}>
              Required for all clients
            </Text>
          </AccordionHeader>
          <AccordionPanel>
            <div className={cn([css.drawerText, css.drawerTextAccordion])}>
              <Text>
                Provide essential information including your organization’s
                name, business activities, and primary place of business for
                legal documentation and regulatory compliance.
              </Text>
              <div className={css.listContainer}>
                <Text variant="bodyBold">Required documents</Text>

                <ul className={css.list}>
                  <li>
                    <Text>
                      <span className={css.drawerTextWeight}>
                        {`${'Proof of address:'} `}
                      </span>
                      If available, submit a document showing your principal
                      place of business, such as a utility bill or recent
                      statement (issued within the last 90 days).
                    </Text>
                  </li>
                </ul>
              </div>
            </div>
          </AccordionPanel>
        </Accordion>

        <Accordion expanded className={css.accordionContainer}>
          <AccordionHeader>
            <Text variant="bodyBold">Business operation details</Text>
            <Text variant="captionRegular" className={css.subText}>
              Required for all clients
            </Text>
          </AccordionHeader>
          <AccordionPanel>
            <div className={cn([css.drawerText, css.drawerTextAccordion])}>
              <Text>
                Depending on your business type, we will request specific
                details tailored to your operations. This may include
                information about the products or services you offer, your
                licensing status, and the industries you are involved in.
              </Text>

              <Text variant="bodyBold">Required documents</Text>

              <div className={css.listContainer}>
                <Text variant="bodyBold">For all clients</Text>

                <ul className={css.list}>
                  <li>
                    <Text>
                      <span className={css.drawerTextWeight}>
                        {`${'Constitutional document:'} `}
                      </span>
                      {'e.g., Articles of Incorporation/Organization, Bylaws, Operating Agreement, Partnership Agreement (not applicable to funds or high-net-worth individuals).'}
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <span className={css.drawerTextWeight}>
                        {`${'Additional documentation (optional):'} `}
                      </span>
                      Provide any other documentation you believe is relevant
                      for your account application.
                    </Text>
                  </li>
                </ul>
              </div>

              <div className={css.listContainer}>
                <Text variant="bodyBold">For funds</Text>

                <ul className={css.list}>
                  <li>
                    <Text>
                      <span className={css.drawerTextWeight}>
                        {`${'Offering document:'} `}
                      </span>
                      A legal document that provides comprehensive details about
                      a securities offering. It includes financial statements,
                      management information, business model, risks, and terms.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <span className={css.drawerTextWeight}>
                        {`${'Fund structure chart:'} `}
                      </span>
                      A chart that visually represents the relationships and
                      flow of funds among entities involved in the fund.
                    </Text>
                  </li>
                </ul>
              </div>

              <div className={css.listContainer}>
                <Text variant="bodyBold">For trusts</Text>

                <ul className={css.list}>
                  <li>
                    <Text>
                      <span className={css.drawerTextWeight}>
                        {`${'Trust agreement:'} `}
                      </span>
                      A financial instrument that represents an investor’s
                      interest in a trust. Typically backed by assets such as
                      real estate or loans, it entitles the holder to a share of
                      the income generated from those assets.
                    </Text>
                  </li>
                </ul>
              </div>
            </div>
          </AccordionPanel>
        </Accordion>

        <Accordion expanded className={css.accordionContainer}>
          <AccordionHeader>
            <Text variant="bodyBold">Anti-money laundering program</Text>
            <Text variant="captionRegular" className={css.subText}>
              {'Only applicable to financial institutions, investment funds, and exchange-traded products or funds (ETP or ETF)'}
            </Text>
          </AccordionHeader>
          <AccordionPanel>
            <div className={cn([css.drawerText, css.drawerTextAccordion])}>
              <Text>
                {'Specify if you maintain an anti-money laundering (AML) program. If you do, indicate which of the following aspects it covers. If not, provide additional context explaining why.'}
              </Text>
              <div className={css.listContainer}>
                <Text variant="bodyBold">
                  Anti-money laundering program details
                </Text>

                <ul className={css.list}>
                  <li>
                    <Text>
                      {'Designated individual(s) responsible for implementing and monitoring the program.'}
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Policies, procedures, and internal controls to facilitate
                      ongoing compliance with AML laws and regulations.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Ongoing screening of investors, customers, and
                      transactions against OFAC sanctions and other watchlists.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Ongoing screening of investors and customers for
                      Politically Exposed Persons.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Policies and procedures for conducting ongoing customer
                      due diligence, including customer identification and
                      beneficial ownership verification.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Policies and procedures to detect and report suspicious
                      activity.
                    </Text>
                  </li>
                  <li>
                    <Text>Periodic independent testing of the program.</Text>
                  </li>
                  <li>
                    <Text>
                      Ongoing AML and sanctions training for appropriate
                      personnel.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Policies and procedures to retain required records.
                    </Text>
                  </li>
                </ul>
              </div>
            </div>
          </AccordionPanel>
        </Accordion>

        <Accordion expanded className={css.accordionContainer}>
          <AccordionHeader>
            <Text variant="bodyBold">Ownership information</Text>
            <Text variant="captionRegular" className={css.subText}>
              Only applicable for institutions not exempt from beneficial
              ownership requirements
            </Text>
          </AccordionHeader>
          <AccordionPanel>
            <div className={cn([css.drawerText, css.drawerTextAccordion])}>
              <Text>
                {'To assist in combating financial crime, federal regulations require Anchorage Digital to verify and record information about key individuals who own or control your organization (i.e., the beneficial owners).'}
              </Text>
              <Text>
                This requires you to provide personal information for
                individuals who qualify as Beneficial Owners and/or Control
                Persons.
              </Text>

              <div className={css.listContainer}>
                <Text variant="bodyBold">
                  What information do I need to provide?
                </Text>
                <Text>
                  You must provide the name, address, date of birth, government
                  issued ID number, and photo ID for:
                </Text>
                <ul className={css.list}>
                  <li>
                    <Text>
                      Each individual, if any, who, directly or indirectly,
                      through any contract, arrangement, understanding,
                      relationship or otherwise, owns 25% or more of the
                      outstanding equity shares of the legal entity.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      An individual with significant responsibility for managing
                      the legal entity, such as an executive officer or senior
                      officer or any other individual who regularly performs
                      similar functions.
                    </Text>
                  </li>
                </ul>
              </div>

              <div className={css.listContainer}>
                <Text variant="bodyBold">Required documents</Text>
                <ul className={css.list}>
                  <Text>
                    <li>
                      <span className={css.drawerTextWeight}>{`${'ID:'} `}</span>
                      {"You'll need to upload an ID for identity verification of each beneficial owner or control person."}
                    </li>
                  </Text>
                  <Text>
                    <li>
                      <span className={css.drawerTextWeight}>
                        {`${'Ownership chart:'} `}
                      </span>
                      {'If there are intermediary layers of ownership between your organization and the ultimate beneficial owner(s), we will also ask you to provide an ownership chart.'}
                    </li>
                  </Text>
                </ul>
              </div>
            </div>
          </AccordionPanel>
        </Accordion>
      </div>
  }
};